<template>
  <div class="apply-sub">
    <van-nav-bar
      title="提交订单"
      fixed
      z-index="999"
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>

    <div class="t-box">
      <div class="cell-group-sub">
        <div class="cell-box active" v-if="schoolName">
          <span class="title-fix">报名驾校</span>
          <span class="title">{{ schoolName }}</span>
        </div>
        <div class="cell-box active" v-if="currentClass.typeDesc != null">
          <span class="title-fix">班型选择</span>
          <span class="choose-box" @click="chooseClass">
            <span class="title">{{
              currentClass
                ? currentClass.typeDesc + "-" + currentClass.name
                : 请选择班型
            }}</span>
            <van-icon size="10" name="arrow" color="#333333" />
          </span>
        </div>
        <div class="cell-box active">
          <span class="title-fix">专属老师</span>
          <a
            :href="'tel:' + kefuPhone"
            class="view-box"
            @click.stop="contactTeacher()"
          >
            <span class="choose-box">
              <span class="title">{{ kefuName }}</span>
            </span>
          </a>
        </div>
      </div>

      <div class="cell-group-sub">
        <div class="cell-box active">
          <span class="title-fix">姓名</span>
          <span class="title">{{ member.name }}</span>

          <!-- <van-field
            class="input-sub"
            input-align="right"
            v-model="member.name"
            placeholder="请输入姓名"
          /> -->
        </div>
        <div class="cell-box active">
          <span class="title-fix">身份证</span>
          <span class="title">{{ member.idNo }}</span>
          <!-- <van-field
            class="input-sub"
            input-align="right"
            v-model="member.idNo"
            placeholder="请输入身份证"
          /> -->
        </div>
        <div class="cell-box active">
          <span class="title-fix">手机号</span>
          <span class="title">{{ member.mobile }}</span>
          <!-- <van-field
            class="input-sub"
            input-align="right"
            v-model="member.mobile"
            placeholder="请输入手机号"
          /> -->
        </div>
      </div>
    </div>

    <div class="agreement-box-sub">
      <img class="select-img" :src="img" @click="chooseAgreement" />
      <span class="left-tex">已阅读并同意</span>
      <span class="right-tex" @click="lookAgreement">《线上报名协议》</span>
    </div>

    <div class="under-pay" @click="underPay">线下支付</div>

    <div class="submid-box">
      <span class="price-box">
        <span class="pay-unit">合计：</span>
        <span class="pay-price">¥{{ currentClass.price }}</span>
      </span>
      <span class="pay-btn" @click="payClick">立即支付</span>
    </div>

    <van-overlay :show="showAlert" :lock-scroll="false" z-index="99">
      <div class="alert-box">
        <div class="alert-view">
          <div class="alert-title">报名协议</div>
          <div class="alert-content-box">
            <div class="alert-content" v-html="agreement"></div>
          </div>
          <!-- <div class="date-box">有效期至</div> -->
          <div class="alert-btn-box">
            <div class="alert-btn" v-if="!isFist" @click="showAlertMethod">
              同意
            </div>
            <van-count-down
              ref="countDown"
              :time="time"
              :auto-start="false"
              v-else
            >
              <template #default="timeData">
                <span class="time-btn" v-if="timeData.seconds != 0"
                  >同意({{ timeData.seconds }}s)</span
                >
                <span class="alert-btn" v-else @click="showAlertMethod"
                  >同意</span
                >
              </template>
            </van-count-down>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showLoading" :lock-scroll="false">
      <div class="loading-box-sub">
        <van-loading size="24px" color="#fff" vertical>支付中...</van-loading>
      </div>
    </van-overlay>

    <div v-html="html"></div>
  </div>
</template>


<script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js" type="text/javascript"></script>

<script>
import {
  Row,
  Col,
  NavBar,
  List,
  Icon,
  Cell,
  CellGroup,
  Field,
  Dialog,
  Toast,
  Overlay,
  CountDown,
  Loading,
} from "vant";
import {
  Order_Get,
  ClassAgreement_Api,
  OrderPay_Api,
  OrderOfflinePay_Api,
  OrderAliPay_Api,
} from "@/api/order";
import { getLocalStorage } from "@/core/utils/local-storage";

import selectImage from "@/assets/other/select.png";
import unselectImage from "@/assets/other/unselect.png";
import { parse } from "qs";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay,
    [CountDown.name]: CountDown,
    [Toast.name]: Toast,
    [Loading.name]: Loading,
  },

  props: {},

  computed: {
    img() {
      return this.isSelect ? selectImage : unselectImage;
    },
  },

  data() {
    return {
      classList: [],
      schoolName: "",
      member: {},
      currentClass: {},
      offlineAlert: "",
      kefuPhone: "",
      kefuName: "",
      agreement: "",
      isSelect: false,
      showAlert: false,
      isFist: true,
      html: "",
      time: 11000,
      canPay: true,
      showLoading: false,
    };
  },

  mounted() {
    let query = parse(getLocalStorage("query"));

    this.$reqGet(ClassAgreement_Api, {
      ...query,
      type: 1,
    }).then((res) => {
      this.agreement = res.map.content;
    });

    this.$reqGet(Order_Get, {
      ...query,
      classId: this.$route.query.itemId,
    }).then((res) => {
      this.classList = res.map.classList;
      this.schoolName = res.map.schoolName;
      this.member = res.map.member;
      this.currentClass = res.map.class;
      this.offlineAlert = res.map.offlineAlert;
      this.kefuName = res.map.kefuName;
      this.kefuPhone = res.map.kefuPhone;
    });
  },
  methods: {
    contactTeacher() {},

    showAlertMethod() {
      this.isSelect = true;
      this.isFist = false;
      this.showAlert = false;
    },

    chooseClass() {
      this.$router.replace({ name: "apply" });
    },
    chooseAgreement() {
      this.isSelect = !this.isSelect;
      if (this.isFist) {
        this.showAlert = true;
        this.$refs.countDown.start();
      }
    },
    lookAgreement() {
      if (this.isFist) {
        this.$refs.countDown.start();
      }
      this.showAlert = true;
    },
    //立即支付
    payClick() {
      let that = this;

      let val = getLocalStorage("themeColor");

      Dialog.confirm({
        messageAlign: "left",
        title: "信息确认",
        confirmButtonColor: val["themeColor"],
        message:
          "姓名：" +
          this.member.name +
          "\n" +
          "身份证：" +
          this.member.idNo +
          "\n" +
          "手机号：" +
          this.member.mobile +
          "\n\n以上信息一经提交不可变更",
      }).then(() => {
        that.makeSure();
      });
    },
    makeSure() {
      if (!this.isSelect) {
        Toast("请同意并勾选报名协议");
        return;
      }

      if (!this.canPay) {
        return;
      }
      this.canPay = false;
      this.showLoading = true;

      var params = {
        ...this.member,
        classId: this.$route.query.itemId,
      };
      this.$reqPost(Order_Get, params).then((res) => {
        if (res.code == 200) {
          let orderId = res.map.orderId;
          this.toPay(orderId);
        } else {
          this.showLoading = false;
          this.canPay = true;
        }
      });
    },
    //
    toPay(orderId) {
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        /*是微信浏览器*/
        this.wxPay(orderId);
      } else {
        this.aliPay(orderId);
      }
    },

    aliPay(orderId) {
      this.$reqPost(OrderAliPay_Api + orderId, {
        pageUrl: "/register/apply/paySuccess/index",
      }).then((res) => {
        this.canPay = true;
        this.html = res.data;
        this.$nextTick(() => {
          document.forms[0].submit();
        });
        //  this.$router.push({ name: "aliPay", params: { html:res.map.body} });
      });
    },

    wxPay(orderId) {
      this.$reqPost(OrderPay_Api + orderId, {
        pageUrl: "/register/apply/paySuccess/index",
      }).then((res) => {
        if (res.map.noOpenId) {
          // this.showLoading = false;
          window.location.href = res.map.url;
        } else {
          if (res.code == 200) {
            let charge = res.map.charge;
            this.play_pay(charge, orderId);
          } else {
            this.showLoading = false;
          }
        }
      });
    },

    play_pay(charge, orderId) {
      var self = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: charge.appId, //公众号名称，由商户传⼊入
          timeStamp: charge.timeStamp, //时间戳 这⾥里随意使⽤用了⼀一个值
          nonceStr: charge.nonceStr, //随机串
          package: charge.package,
          signType: charge.signType,
          paySign: charge.paySign,
        },
        function (res) {
          self.showLoading = false;
          self.canPay = true;
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            self.$router.replace({
              name: "paySuccess",
              params: {orderId:orderId},
            });
          } else {
            self.$router.replace({
              name: "payOrder",
              query: {
                orderId: orderId,
                classId: self.$route.query.itemId,
                itemId: self.$route.query.itemId,
              },
            });
          }
        }
      );
    },
    //线下支付
    underPay() {
      if (!this.isSelect) {
        Toast("请同意并勾选报名协议");
        return;
      }

      let val = getLocalStorage("themeColor");
      Dialog.alert({
        title: "线下支付方式",
        message: this.offlineAlert,
        confirmButtonColor: val["themeColor"],
        theme: "round-button",
        messageAlign: "left",
      }).then(() => {
        this.toUnderPaySuccess();
      });
    },

    toUnderPaySuccess() {
      let that = this;
      var params = {
        ...this.member,
        classId: this.$route.query.itemId,
      };
      this.$reqPost(Order_Get, params).then((res) => {
        let orderId = res.map.orderId;
        that.$reqPost(OrderOfflinePay_Api + orderId, params).then((res) => {
          this.$router.replace({
            name: "paySuccess",
            params: {
              params: {
                name: that.kefuName,
                mobile: that.kefuPhone,
                isUnder: true,
                orderId:orderId
              },
            },
          });
        });
      });
    },

    toDetail(res) {
      this.$router.replace({ name: "classDetail", params: { itemId: res.id } });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
// .content {
//   font-size: 20px;
//   display: flex;
//   flex-direction: column;
//   font-size: 12px;
// }
// .colorRed {
//   font-weight: 300;
//   color: #e02020;
// }
// span {
//   font-weight: 300;
// }
// p {
//   padding: 0;
//   font-weight: 300;
// }
// .fontWeightBig {
//   font-weight: bold;
// }
// .fontWeightFour {
//   font-weight: 400;
// }
// .table {
//   border-collapse: collapse;
//   text-align: center;
//   font-size: 12px;
// }
.loading-box-sub {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.under-pay {
  color: #666666;
  font-size: 14px;
  text-decoration: underline;
  margin: 16px auto 96px auto;
  text-align: center;
}
.submid-box {
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding-bottom: 20px;
  background-color: white;
  justify-content: space-between;
  .price-box {
    display: flex;
    align-items: center;
    .pay-unit {
      color: #ff2525;
      font-size: 12px;
      margin-left: 33px;
    }
    .pay-price {
      color: #ff2525;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .pay-btn {
    width: 199px;
    height: 44px;
    color: white;
    background: var(--theme-color);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    margin-right: 16px;
  }
}

.agreement-box-sub {
  margin: 24px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .left-tex {
    color: #666666;
    font-size: 12px;
    margin-left: 4px;
  }
  .right-tex {
    color: var(--theme-color);
    font-size: 12px;
    text-decoration: underline;
  }

  .select-img {
    width: 12px;
    height: 12px;
  }
}
.input-sub {
  width: 180px;
  background-color: transparent;
}
.van-cell {
  background-color: transparent;
}
.cell-group-sub {
  width: 343px;
  margin: 16px auto 0 auto;
  overflow: hidden;
  border-radius: 8px;
  background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
  border: 1px solid #999999;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .cell-box {
    height: 52px;
    margin: 0 8px;
    width: 327px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      color: #242424;
    }
    .title-fix {
      min-width: 90px;
      flex: 1;
      font-size: 14px;
      color: #333333;
    }
    .title-red {
      color: var(--theme-color);
      font-size: 14px;
    }
  }
  .cell-box.active {
    border-bottom: 1px solid #e5e5e5;
  }
  .choose-box {
    display: flex;
    align-items: center;
  }
}

.custom-title {
  font-size: 14px;
  color: #333333;
}
.apply-sub {
  width: 100%;
  .alert-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .alert-view {
      width: 343px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      max-height: 80vh;
      background-color: white;

      .alert-title {
        min-height: 46px;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        color: #333333;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .alert-content-box {
        display: block;
        flex: 1;
        .alert-content {
          overflow-x: hidden;
          margin: 16px;
          max-height: 50vh;
          overflow-y: auto;
        }
      }
      .date-box {
        height: 20px;
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
        margin-left: 16px;
      }

      .alert-btn-box {
        min-height: 72px;
        width: 100%;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;
        .alert-btn {
          width: 199px;
          height: 44px;
          background: var(--theme-color);
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
        .time-btn {
          width: 199px;
          height: 44px;
          background-color: #999999;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
      }
    }
  }

  .t-box {
    padding: 16px 0;
    margin: 8px auto;
    width: 100%;
    background-color: white;
  }
}
</style>
